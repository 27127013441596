export const darkTheme = {
  bg: "#403d39", // Black background for a sleek, dark look
  bgLight: "#333333", // Dark gray background for sections
  primary: "#FF0000", // Bold red as the primary color
  text_primary: "#FFFFFF", // White text for readability on dark background
  text_secondary: "#D3D3D3", // Light gray for secondary text
  card: "#1C1C1C", // Dark card background to match the theme
  card_light: "#333333", // Slightly lighter dark gray for secondary elements
  button: "#FF0000", // Red button to match primary color
  white: "#FFFFFF", // White for contrast
  black: "#000000", // Black for deep contrasts // Retained black for deep contrasts
};

export const lightTheme = {
  bg: "#FFFFFF", // Light background for a clean look
  bgLight: "#F5F5F5", // Lighter gray background for sections
  primary: "#FF0000", // Bold red to match dark theme's primary color
  text_primary: "#111111", // Dark text for readability on light background
  text_secondary: "#48494a", // Medium gray for secondary text
  card: "#FFFFFF", // White card background for light theme
  card_light: "#F0F0F0", // Slightly darker light gray for secondary elements
  button: "#FF0000", // Red button to match primary color
  white: "#FFFFFF", // White for contrast
  black: "#000000", // Black for deep contrasts
};
